import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { navigate } from 'gatsby-link';
import { connect, useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import getSlug from '../../utils/getSlug';
import setInitialColors from '../../utils/setInitialColors';
import { setSearchFullscreen } from '../../state/searchSlice';
import { setMenuFullscreen } from '../../state/menuFullscreenSlice';
import MenuFullscreenMobile from './MenuFullscreenMobile';
import MenuIconsBar from './MenuIconsBar';
import MenuFullscreenDesktopNew from './MenuFullscreenDesktopNew';
import Popup from '../common/popup/Popup';
import SearchMenuComponent from '../search/SearchMenuComponent';

const StyledMenuWrapper = styled.div`
	display: block;
`;

const StyledFixedWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	height: 70px;
	justify-content: flex-end;
	left: 0;
	padding: 10px 20px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 105;
	&.sticky {
		align-items: center;
		background-color: ${({ theme }) => theme.colors.darkBlue};
		height: 70px;
	}
	&.expanded {
		align-items: center;
		background-color: ${({ isSingleNewsPage, theme, isError }) =>
			isSingleNewsPage || isError ? theme.colors.darkBlue : 'transparent'};
		width: ${({ isSingleNewsPage, isError }) => (isSingleNewsPage || isError ? '100%' : '100vw')};
	}
	&.hidden {
		display: none;
	}

	@media (min-width: 1366px) {
		justify-content: space-between;
		padding: 64px calc(100vw / 24) 40px calc(100vw / 24);
		align-items: flex-end;
		&.expanded {
			height: ${({ isSingleNewsPage, isError }) => (isSingleNewsPage || isError ? '200px' : '220px')};
		}
		&.sticky {
			height: ${({ isError }) => (isError ? '80px' : '80px')};
			align-items: center;
			padding: 0 calc(100vw / 24) 0 calc(100vw / 24);
		}
		&.fullscreen {
			align-items: flex-start;
			background-color: transparent;
			width: 100%;
			height: 220px;
		}
	}
`;

const StyledMobileIconWrapper = styled.div`
	display: flex;
	@media (min-width: 1366px) {
		display: none;
	}
`;

const StyledMobileIcon = styled.div`
	-webkit-user-select: none;
	display: block;
	height: 42px;
	padding: 12px 11px;
	user-select: none;
	width: 42px;
	z-index: 1002;

	span {
		background: ${({ theme }) => theme.colors.white};
		display: block;
		height: 2px;
		margin-bottom: 7px;
		position: relative;
		transform-origin: 4px 0;
		transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
		width: 30px;
		z-index: 1;

		&:first-of-type {
			transform-origin: 0 0;
		}

		&:nth-of-type(2) {
			transform-origin: 0 100%;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&.menu-opened {
		//padding: 14px 13px;
		span {
			&:first-of-type {
				transform: rotate(45deg) translate(2px, -1px);
			}
			&:nth-of-type(2) {
				opacity: 0;
				transform: rotate(0deg) scale(0.2, 0.2);
			}
			&:nth-of-type(3) {
				transform: rotate(-45deg) translate(-1px, 0);
			}
		}
	}
`;
const StyledNavigationWrapper = styled.div`
	//padding-left: 50px;
	//width: calc((100% - 226px) / 2 + 5px);
	display: none;
	flex-direction: row;
	justify-content: flex-start;
	width: calc((100% - 226px) / 2);

	@media (min-width: 1366px) {
		display: flex;
		height: 100%;
		flex-wrap: wrap;
		&.sticky {
			height: unset;
			align-items: center;
		}
	}
	@media (min-width: 1840px) {
		flex-wrap: nowrap;
	}
`;

const StyledSingleMenuItem = styled.div`
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 0.9375rem;
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	line-height: 1em;
	margin-right: 35px;
	position: relative;
	text-decoration: none;
	&:hover {
		color: ${({ theme }) => theme.colors.green};
	}
	@media (min-width: 1440px) {
		margin-right: 45px;
		line-height: 1.25em;
	}
	@media (min-width: 1366px) and (max-width: 1439px) {
		margin-right: 25px;
	}
	&:after {
		opacity: 0;
		transition: all 2s ease-in-out;
	}
	&.active {
		color: ${({ theme }) => theme.colors.green};
		&:after {
			background-color: ${({ theme }) => theme.colors.green};
			content: '';
			height: 1px;
			left: 0;
			opacity: 1;
			position: absolute;
			top: 30px;
			transition: all 2s ease-in-out;
			width: 100%;
		}
	}
`;

const StyledLogoWrapper = styled(Link)`
	display: none;
	margin-top: -20px;
	width: 226px;
	&.isSingleNews,
	&.isError {
		display: flex;
		height: 30px;
		left: 50%;
		position: absolute;
		transform: translateX(-50%);
		width: 30px;
		margin-top: 0;
		@media (min-width: 1366px) {
			height: 100%;
			left: 0;
			position: relative;
			transform: translateX(0%);
			margin-top: -20px;
			width: 226px;
		}
	}
	&.sticky {
		display: flex;
		height: 30px;
		left: 50%;
		position: absolute;
		transform: translateX(-50%);
		width: 30px;
		margin-top: 0;
	}
	img {
		max-height: 80px;
		width: 100%;
	}

	@media (min-width: 1366px) {
		display: flex;
		&.sticky {
			height: 50px;
			width: 45px;
			position: static;
			transform: translateX(0);
		}
	}
`;

const Menu = ({
	menuData,
	activeSite,
	globals,
	otherPagesPathsForLangChange,
	preparedSitemapData,
	preparedSitemapSimplify,
	isSingleNewsPage,
	slug,
}) => {
	const menuItems = menuData?.nodes || [];
	const pageTheme = useSelector((state) => state.pageTheme.pageTheme);
	const menuFullscreen = useSelector((state) => state.menuFullscreen.menuFullscreen);
	const searchFullscreen = useSelector((state) => state.searchFullscreen.searchFullscreen);
	const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
	const [parentItemSlug, setParentItemSlug] = useState('');
	const dispatch = useDispatch();
	const globalData = globals?.nodes?.[0] || [];
	const searchInPortText = globalData?.searchInPortText || '';
	const linkToHomePage = globalData?.linkToHomepage?.slug || '';
	const logoBig = globalData?.logoBig?.url || '';
	const logoSmall = globalData?.logoSmall?.url || '';
	const socialMediaData = globalData?.socialMedia || [];
	const readMore = globalData?.readMore || '';
	const modalText = globals?.nodes?.[0]?.modalText || '';
	const btnClose = globals?.nodes?.[0]?.btnClose || '';
	const btnLinkTo = globals?.nodes?.[0]?.btnLinkTo || '';
	const [linkTo, setLinkTo] = useState('');
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [categoryTitle, setCategoryTitle] = useState('');
	const [isStickyMode, toggleStickyMode] = useState(false);
	const [activeMenuItemId, setActiveMenuItemId] = useState(null);
	const [isMobileState, setIsMobileState] = useState(false);
	const [activeMenuItemKey, setActiveMenuItemKey] = useState(null);

	const toggleMenuStickyMode = () => {
		const footerElement = document.getElementById('footer');
		const footerOffset = footerElement?.offsetTop || 0;
		if (window.scrollY > 200 && !isStickyMode) {
			toggleStickyMode(true);
		} else if (window.scrollY === 0 && isStickyMode) {
			toggleStickyMode(false);
		}
		if (window.scrollY >= footerOffset) {
			setIsScrolledToBottom(true);
		} else {
			setIsScrolledToBottom(false);
		}
	};

	const onMenuItemClick = (menuItem, key) => {
		setActiveMenuItemKey(key);
		const isAnyOfNestedPages =
			menuItem.menuItemTitle.includes('Porty') ||
			menuItem.menuItemTitle.includes('Ports') ||
			menuItem.menuItemTitle.includes('Häfen') ||
			menuItem.menuItemTitle.includes('Biznes') ||
			menuItem.menuItemTitle.includes('Business') ||
			menuItem.menuItemTitle.includes('Unser Angebot') ||
			menuItem.menuItemTitle.includes('Spółka') ||
			menuItem.menuItemTitle.includes('Company') ||
			menuItem.menuItemTitle.includes('Gesellschaft');
		if (isAnyOfNestedPages) {
			setCategoryTitle(menuItem.menuItemTitle);
			const tempParentSlug = (() => {
				switch (menuItem.menuItemTitle) {
					case 'Porty':
						return 'porty';
					case 'Ports':
						return 'ports';
					case 'Häfen':
						return 'hafen';
					case 'Biznes':
						return 'biznes';
					case 'Business':
						return 'business';
					case 'Unser Angebot':
						return 'unser-angebot';
					case 'Spółka':
						return 'spolka';
					case 'Company':
						return 'company';
					case 'Gesellschaft':
						return 'gesellschaft';
					default:
						return '';
				}
			})();
			setParentItemSlug(tempParentSlug);
		}
		let newSlug = '';
		if (menuItem.linkToPage?.slug) {
			newSlug += menuItem.linkToPage?.slug;
		}
		if (menuItem?.linkToExternalPage) {
			if (menuItem?.showModal) {
				setIsOpenModal(true);
				setLinkTo(menuItem.linkToExternalPage);
				setActiveMenuItemKey(null);
				if (isOpenModal) {
					window.open(menuItem.linkToExternalPage, '_blank').focus();
				}
			}
		}

		if (menuItem.treeChildren.length > 0) {
			orderMenuListByPosition(menuItem);
			if (menuItem.originalId === activeMenuItemId && menuFullscreen) {
				dispatch(setMenuFullscreen(false));
			} else {
				dispatch(setMenuFullscreen(true));
			}

			setActiveMenuItemId(menuItem.originalId);
		} else {
			dispatch(setMenuFullscreen(false));
			if (newSlug) {
				navigate(`/${getSlug(newSlug, activeSite)}`);
			}
		}
	};
	const togglePopup = () => {
		setIsOpenModal(!isOpenModal);
	};

	const menuFullscreenStateRef = useRef();
	menuFullscreenStateRef.current = menuFullscreen;
	const isMobileStateRef = useRef(isMobileState);
	isMobileStateRef.current = isMobileState;

	const handleMenuOnResize = () => {
		const windowWidth = window?.innerWidth || 0;

		if (windowWidth < 1366 && !isMobileStateRef.current) {
			dispatch(setMenuFullscreen(false));
			setIsMobileState(true);
		} else if (windowWidth >= 1366 && isMobileStateRef.current) {
			dispatch(setMenuFullscreen(false));
			setIsMobileState(false);
		}
	};

	useEffect(() => {
		handleMenuOnResize();
		const escFunction = (e) => {
			if (e.keyCode === 27 && menuFullscreenStateRef.current) {
				dispatch(setMenuFullscreen(false));
				setActiveMenuItemKey(null);
			}
		};

		window.addEventListener('keydown', escFunction);
		window.addEventListener('resize', handleMenuOnResize);

		return () => {
			window.removeEventListener('keydown', escFunction);
			window.removeEventListener('resize', handleMenuOnResize);
		};
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', toggleMenuStickyMode);
		return () => {
			window.removeEventListener('scroll', toggleMenuStickyMode);
		};
	}, [isStickyMode]);

	useEffect(() => {
		setInitialColors(pageTheme);
	}, [pageTheme]);

	useEffect(() => {
		if (menuFullscreen === false) {
			setActiveMenuItemKey(null);
		}
	}, [menuFullscreen]);
	const searchFullscreenHandler = () => {
		dispatch(setSearchFullscreen(!searchFullscreen));
	};

	const orderMenuListByPosition = (item) => {
		item?.treeChildren?.sort((a, b) => (a.position > b.position ? 1 : -1));
	};

	const submenuItems = menuItems.find((element) => element.originalId === activeMenuItemId);
	const necessaryCookies = Cookies.get('necessaryCookies');
	const isMenuFullscreenVisible = !isMobileState && menuFullscreen && submenuItems;

	const isError = slug === '404';

	return (
		<>
			{isOpenModal && (
				<Popup
					visible={isOpenModal}
					href={linkTo}
					close={() => togglePopup()}
					modalText={modalText}
					btnLinkTo={btnLinkTo}
					btnClose={btnClose}
				/>
			)}
			<StyledMenuWrapper>
				<MenuFullscreenDesktopNew
					isMenuFullscreenActive={isMenuFullscreenVisible}
					globals={globals}
					preparedSitemapData={preparedSitemapData}
					isExpanded={menuFullscreen}
					parentSlug={parentItemSlug || ''}
					activeSite={activeSite}
					readMore={readMore}
					activeMenuItemId={activeMenuItemId}
					categoryTitle={categoryTitle}
					isSticky={isStickyMode}
					// handleResetActiveItem={setActiveMenuItemKey(null)}
				/>
				{isMobileState && (
					<MenuFullscreenMobile
						menuFullscreen={menuFullscreen}
						globals={globals}
						preparedSitemapData={preparedSitemapData}
						activeSite={activeSite}
						socialMediaData={socialMediaData}
						otherPagesPathsForLangChange={otherPagesPathsForLangChange}
						necessaryCookies={necessaryCookies}
					/>
				)}
				<StyledFixedWrapper
					className={`contrast-mode-background ${
						!menuFullscreen && isStickyMode && !isScrolledToBottom
							? 'sticky'
							: menuFullscreen && isStickyMode
							? 'fullscreen'
							: isScrolledToBottom
							? 'hidden'
							: 'expanded'
					}`}
					isSingleNewsPage={isSingleNewsPage}
					isError={isError}
					pageTheme={pageTheme}
					menuFullscreen={menuFullscreen}
				>
					<StyledNavigationWrapper className={isStickyMode && !isScrolledToBottom ? 'sticky' : ''}>
						{menuItems.map((item, key) => {
							const menuItemTitle = item?.menuItemTitle || '';

							return (
								<StyledSingleMenuItem
									className={activeMenuItemKey === key ? 'active' : ''}
									key={menuItemTitle + item.position}
									onClick={() => onMenuItemClick(item, key)}
								>
									{menuItemTitle}
								</StyledSingleMenuItem>
							);
						})}
					</StyledNavigationWrapper>
					<StyledLogoWrapper
						onClick={() => {
							dispatch(setMenuFullscreen(false));
							setActiveMenuItemKey(null);
						}}
						className={
							isStickyMode && !menuFullscreen
								? 'sticky'
								: isSingleNewsPage && !isStickyMode
								? 'isSingleNews'
								: isError && !isStickyMode
								? 'isError'
								: ''
						}
						to={`/${getSlug(linkToHomePage, activeSite)}`}
					>
						<img
							alt="port Szczecin"
							src={
								(isStickyMode && !menuFullscreen) || (isSingleNewsPage && isMobileState) || (isError && isMobileState)
									? logoSmall
									: logoBig
							}
						/>
					</StyledLogoWrapper>
					<StyledMobileIconWrapper onClick={() => dispatch(setMenuFullscreen(!menuFullscreen))}>
						<StyledMobileIcon className={menuFullscreen ? 'menu-opened' : ''}>
							<span />
							<span />
							<span />
						</StyledMobileIcon>
					</StyledMobileIconWrapper>
					<MenuIconsBar
						isStickyMode={isStickyMode}
						menuFullscreen={menuFullscreen}
						globalData={globalData}
						searchFullscreenHandler={searchFullscreenHandler}
						socialMediaData={socialMediaData}
						otherPagesPathsForLangChange={otherPagesPathsForLangChange}
						searchInPortText={searchInPortText}
						necessaryCookies={necessaryCookies}
						preparedSitemapSimplify={preparedSitemapSimplify}
						activeSite={activeSite}
					/>
				</StyledFixedWrapper>
				{searchFullscreen && <SearchMenuComponent globalData={globalData} activeSite={activeSite} />}
			</StyledMenuWrapper>
		</>
	);
};

export default connect((state) => ({
	pageTheme: state.pageTheme.pageTheme,
	menuFullscreen: state.menuFullscreen.menuFullscreen,
}))(Menu);
