import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SimpleBar from 'simplebar-react';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import ExtendedOption from './extendedOption/ExtendedOption';
import Button from '../common/Button';
import 'simplebar/dist/simplebar.min.css';

const StyledOptionsModuleWrapper = styled.div`
	//max-height: 600px;
	background-color: ${({ theme }) => theme.colors.white};
	border: 2px solid ${({ theme }) => theme.colors.darkBlue};
	color: ${({ theme }) => theme.colors.darkBlue};
	height: calc(100% - 10px);
	left: 50%;
	max-width: 800px;
	padding: 0 0 20px;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% - 10px);
	z-index: 201;

	@media (min-width: 768px) {
		min-height: 580px;
		width: 710px;
		height: 600px;
	}

	.scroll-custom {
		max-height: calc(var(--inner-height) - 330px);
		@media (min-width: 768px) {
			max-height: 390px;
		}
		&-description {
			max-height: calc(var(--inner-height) - 330px);
			@media (min-width: 768px) {
				max-height: 370px;
			}
		}
	}
`;

const StyledOptionsWrapper = styled.div`
	margin-bottom: 20px;
	// max-height: calc(var(--inner-height) - 330px);
	// overflow-y: auto;

	// @media (min-width: 768px) {
	// 	margin-right: 5px;
	// 	// max-height: 390px;
	// 	// to hide scrollbar
	// 	//scrollbar-width: none;
	// 	//::-webkit-scrollbar {
	// 	//	background: transparent; /* Remove scrollbar space */
	// 	//	width: 0; /* Optional: just make scrollbar invisible */
	// 	//}
	// }
`;

const StyledModalBtnsWrapper = styled.div`
	align-items: center;
	bottom: 20px;
	display: flex;
	flex-direction: column;
	left: 40px;
	position: absolute;
	width: calc(100% - 80px);
	button {
		width: 230px;
	}

	@media (min-width: 768px) {
		justify-content: space-between;
		flex-direction: row;
		button {
			min-width: 180px;
			width: auto;
			font-weight: ${({ theme }) => theme.fontWeight.black};
		}
	}
`;

const StyledDescription = styled.div`
	font-size: 1rem;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 1.75rem;
	//max-height: calc(var(--inner-height) - 330px);
	//overflow-y: auto;
	padding: 0 40px;
	// @media (min-width: 768px) {
	// 	margin-right: 5px;
	// 	//max-height: 370px;
	// 	// &::-webkit-scrollbar {
	// 	// 	//padding-left: 30px;
	// 	// 	height: 80px;
	// 	// 	width: 10px;
	// 	// }
	// 	//
	// 	// &::-webkit-scrollbar-track {
	// 	// 	opacity: 0;
	// 	// }
	// 	//
	// 	// &::-webkit-scrollbar-thumb {
	// 	// 	-webkit-border-radius: 10px;
	// 	// 	background: ${({ theme }) => theme.colors.darkBlue};
	// 	// 	border-radius: 10px;
	// 	// }
	// }
`;

const StyledTabTitleWrapper = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	flex-wrap: nowrap;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	height: 50px;
	left: 0;
	position: absolute;
	width: 100%;
	z-index: 61;
	@media (min-width: 1024px) {
		height: 60px;
	}
`;

const StyledTabTitle = styled.div`
	align-items: center;
	display: flex;
	font-size: 0.8125rem;
	height: 100%;
	justify-content: center;
	line-height: 1;
	padding: 0 10px;
	position: relative;
	text-align: center;
	text-transform: uppercase;
	user-select: none;
	width: 100%;
	&.inactive-tab {
		border-bottom: 1px solid ${({ theme }) => theme.colors.darkBlue};
		&:first-of-type {
			border-right: 1px solid ${({ theme }) => theme.colors.darkBlue};
		}
		&:last-of-type {
			border-left: 1px solid ${({ theme }) => theme.colors.darkBlue};
		}
	}

	div {
		position: relative;
		&:before {
			background-color: ${({ theme }) => theme.colors.darkBlue};
			bottom: -8px;
			content: '';
			height: 1px;
			left: calc(50% - 25px);
			position: absolute;
			transform: scaleX(0);
			transform-origin: 50%;
			transition: transform 0.2s ease-in-out;
			width: 50px;
		}
	}

	&.active-tab {
		border-bottom: 1px solid transparent;

		div {
			&:before {
				transform: scaleX(1);
				width: 50px;
			}
		}
	}
	@media (min-width: 1024px) {
		&:hover {
			div {
				&:before {
					transform: scaleX(1);
					width: 50px;
				}
			}
		}
	}
`;
const StyledTab = styled.div`
	//height: 100%;
	//position: absolute;
	background-color: ${({ theme }) => theme.colors.white};
	bottom: 0;
	color: black;
	display: none;
	flex-direction: column;
	left: 0;
	padding: 90px 0 0;
	width: 100%;

	&.second {
		padding: 90px 0 0;
	}
	&.active {
		display: flex;
	}

	@media (min-width: 768px) {
		padding: 120px 0 0;
		&.second {
			padding: 100px 0 0;
		}
	}
`;

const CookieInfoOptionsModal = ({
	modalData,
	saveCookiesOptions,
	cookiesSettings,
	changeSettings,
	acceptAll,
	toggleCookiesInfoOptionsModal,
}) => {
	const [activeCookiesTab, setActiveCookiesTab] = useState(1);

	const setHeightOnResize = () => {
		const { innerHeight } = window;
		document.documentElement.style.setProperty('--inner-height', `${innerHeight}px`);
		// document.documentElement.style.setProperty('--inner-height', `100vh`);
	};

	useEffect(() => {
		window.addEventListener('resize', setHeightOnResize);

		return () => {
			window.removeEventListener('resize', setHeightOnResize);
		};
	}, []);

	const handleTabClick = (key) => {
		if (activeCookiesTab !== key) {
			setActiveCookiesTab(key);
		}
	};

	return (
		<StyledOptionsModuleWrapper>
			<StyledTabTitleWrapper>
				<StyledTabTitle className={`${activeCookiesTab === 0 ? `active-tab` : `inactive-tab`}`} onClick={() => handleTabClick(0)}>
					<div>{modalData.titleFirstTab}</div>
				</StyledTabTitle>
				<StyledTabTitle className={`${activeCookiesTab === 1 ? `active-tab` : `inactive-tab`}`} onClick={() => handleTabClick(1)}>
					<div>{modalData.titleSecondTab}</div>
				</StyledTabTitle>
			</StyledTabTitleWrapper>
			<StyledTab className={activeCookiesTab === 0 ? 'active' : ''}>
				<SimpleBar className="scroll-custom-description">
					<StyledDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(modalData.advancedContent)} />
				</SimpleBar>
			</StyledTab>
			<StyledTab className={`second ${activeCookiesTab === 1 ? 'active' : ''}`}>
				<SimpleBar className="scroll-custom">
					<StyledOptionsWrapper>
						<ExtendedOption
							checked={cookiesSettings.necessaryCookies}
							onChange={(e) => changeSettings('necessaryCookies', e.target.checked)}
							text={modalData.necessaryCookiesText}
							description={modalData.necessaryCookiesDescription}
							link={modalData.necessaryCookiesLink}
							isDisabled
						/>
						<ExtendedOption
							checked={cookiesSettings.isGoogleAnalytics}
							onChange={(e) => changeSettings('isGoogleAnalytics', e.target.checked)}
							text={modalData.googleAnalyticsText}
							description={modalData.googleAnalyticsDescription}
							link={modalData.googleAnalyticsLink}
						/>
						<ExtendedOption
							checked={cookiesSettings.isFacebookPixel}
							onChange={(e) => changeSettings('isFacebookPixel', e.target.checked)}
							text={modalData.facebookPixelText}
							description={modalData.facebookPixelDescription}
							link={modalData.facebookPixelLink}
						/>
						<ExtendedOption
							checked={cookiesSettings.isGoogleTagManager}
							onChange={(e) => changeSettings('isGoogleTagManager', e.target.checked)}
							text={modalData.googleTagManagerText}
							description={modalData.googleTagManagerDescription}
							link={modalData.googleTagManagerLink}
						/>
						<ExtendedOption
							checked={cookiesSettings.isHotJar}
							onChange={(e) => changeSettings('isHotJar', e.target.checked)}
							text={modalData.hotjarCookiesText}
							description={modalData.hotjarCookiesDescription}
							link={modalData.hotjarCookiesLink}
						/>
					</StyledOptionsWrapper>
				</SimpleBar>
			</StyledTab>
			<StyledModalBtnsWrapper>
				<Button className="always-dark-blue" action={() => toggleCookiesInfoOptionsModal()}>
					{modalData.cancelBtnText}
				</Button>

				{activeCookiesTab === 0 ? (
					<Button className="always-dark-blue" action={() => setActiveCookiesTab(1)}>
						{modalData.customizeBtnText}
					</Button>
				) : (
					<Button className="always-dark-blue" action={() => saveCookiesOptions()}>
						{modalData.acceptSelectedBtnText}
					</Button>
				)}
				<Button className="dark-bg" action={() => acceptAll()}>
					{modalData.acceptAllBtnText}{' '}
				</Button>
			</StyledModalBtnsWrapper>
		</StyledOptionsModuleWrapper>
	);
};

export default CookieInfoOptionsModal;
