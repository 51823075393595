import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import setInitialColors from '../../../utils/setInitialColors';
import { setPageTheme } from '../../../state/pageThemeSlice';

const StyledContrastSwitcher = styled.div`
	-ms-flex-pack: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: 15px;
	@media (min-width: 1600px) {
		margin-right: 40px;
	}
	&.footer {
		margin-right: 20px;
		align-items: center;
	}
	img {
		height: 25px;
	}
	&:after {
		background-color: ${({ theme }) => theme.colors.white};
		border-radius: 50%;
		content: '';
		display: none;
		height: 25px;
		left: 4px;
		position: absolute;
		width: 25px;
		z-index: -1;
	}
`;

const ContrastSwitcher = ({ pageTheme, contrastOnIcon, contrastOffIcon, contrastDarkIcon, isFooter }) => {
	const dispatch = useDispatch();
	const setContrast = () => {
		setInitialColors('contrastMode');
		if (pageTheme !== 'contrastMode') {
			dispatch(setPageTheme('contrastMode'));
		} else {
			dispatch(setPageTheme('lightMode'));
		}
	};
	return (
		<StyledContrastSwitcher className={isFooter ? 'footer' : ''} onClick={() => setContrast()}>
			<img
				className="contrast"
				src={pageTheme === 'contrastMode' ? contrastOnIcon : isFooter ? contrastDarkIcon : contrastOffIcon}
				alt="contrast"
			/>
		</StyledContrastSwitcher>
	);
};

export default connect((state) => ({
	pageTheme: state.pageTheme.pageTheme,
}))(ContrastSwitcher);
