import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import getSlug from '../../utils/getSlug';
import setInitialColors from '../../utils/setInitialColors';
import CloseIcon from '../../assets/inline/close-icon.inline.svg';
import { setMenuFullscreen } from '../../state/menuFullscreenSlice';
import Popup from '../common/popup/Popup';

const StyledWrapper = styled.div`
	//transition: all 0.3s ease-in-out;
	align-items: flex-start;
	background-color: var(--menu-color);
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: flex-start;
	left: 0;
	overflow-y: scroll;
	padding: 212px calc(100vw / 24) 48px calc(100vw / 24);
	position: fixed;
	top: 0;
	transform: translateY(-120%);
	transition: transform 0.2s linear, opacity 0.2s linear;
	width: 100%;
	z-index: 105;
	@media (min-height: 900px) and (min-width: 1366px) {
		padding: 100px 0 48px 63px;
	}
	@media (min-width: 1366px) {
		padding: 212px 0 48px 63px;
	}
	&.active {
		transform: translateY(0);
		transition: transform 0.15s linear, opacity 0.15s linear 0.3s;
	}
	div.no-link {
		cursor: default;
	}
`;

const StyledColumnsMenuWrapper = styled.div`
	//width: calc(100% - 200px);
	//border-top: 1px solid rgba(255, 255, 255, 0.5);
	//border: 2px solid red;
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	border-left: 1px solid rgba(255, 255, 255, 0.5);
	border-top: 1px solid rgba(255, 255, 255, 0.5);
	border-right: 1px solid rgba(255, 255, 255, 0.5);
	height: 100%;
	display: grid;
	grid-template-columns: minmax(min-content, 31%) max-content minmax(min-content, 31%) max-content minmax(min-content, 31%);
	//padding: 120px 0 40px 116px; // 80px + 116px
	padding: 140px 60px 10px;
	position: relative;
	transition: transform 0.2s linear, opacity 0.2s linear;
	opacity: 0;
	width: 100%;
	overflow-y: scroll;
	::-webkit-scrollbar {
		background: transparent;
		width: 0;
	}
	@media (min-width: 1366px) {
		grid-template-columns: minmax(min-content, 30%) max-content minmax(min-content, 30%) max-content minmax(min-content, 30%);
		width: calc(2 / 3 * 100%);
		padding: 60px 0 0 90px;
		@media (min-height: 901px) {
			padding: 90px 0 20px 116px;
		}
		@media (max-height: 900px) {
			padding: 80px 0 0 100px;
		}
	}
	&.active {
		transition: transform 0.15s linear, opacity 0.15s linear 0.5s;
		opacity: 1;
	}
`;

const FirstColumn = styled.div`
	grid-column: 1;
`;

const SecondColumn = styled.div`
	grid-column: 3;
	opacity: 0;
	transform: translateX(-30px);
	&.active {
		opacity: 1;
		transform: translateX(0);
		transition: opacity 0.3s ease-in-out, transform 0.4s ease-in-out;
	}
`;

const ThirdColumn = styled.div`
	grid-column: 5;
	opacity: 0;
	transform: translateX(-30px);
	&.active {
		opacity: 1;
		transform: translateX(0);
		transition: opacity 0.3s ease-in-out, transform 0.4s ease-in-out;
	}
`;

const StyledSingleMenuItem = styled.div`
	//background-color: royalblue;
	//margin-right: 52px;
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 1.0625rem;
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	line-height: 1.25rem;
	position: relative;
	text-decoration: none;
	transform: translateX(0);
	transition: transform 0.3s ease-in-out;
	.link-item {
		//background-color: mediumvioletred;
	}
	.link-item-no-link {
		//background-color: gold;
	}
	&:hover {
		color: ${({ theme }) => theme.colors.green};
	}
	.simple-item {
		&:hover {
			color: ${({ theme }) => theme.colors.green};
		}
	}
	@media (min-width: 1024px) {
		//margin-bottom: 35px;
		padding-bottom: 24px;
		@media (min-height: 900px) {
			padding-bottom: 18px;
		}
	}
	@media (max-height: 900px) {
		line-height: 1rem;
		font-size: 0.75rem;
	}
	&.visible {
		color: ${({ theme }) => theme.colors.green};
		transform: translateX(10px);
		transition: transform 0.3s ease-in-out;
		a {
			color: ${({ theme }) => theme.colors.green};
		}
	}
	a {
		color: ${({ theme }) => theme.colors.white};
		font-weight: 500;
		text-decoration: none;
		&:hover {
			color: ${({ theme }) => theme.colors.green};
		}
	}
`;

const StyledHorizontalLine = styled.div`
	//transition: opacity 0.15s ease-in-out, width 0.15s ease-in-out;
	//width: 0;
	background-color: ${({ theme }) => theme.colors.green};
	height: 2px;
	left: -35px;
	opacity: 0;
	position: absolute;
	top: -11px;
	transform: scaleX(0);
	transform-origin: left;
	transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
	width: 25px;
	&.visible {
		opacity: 1;
		transform: scaleX(1);
		width: 25px;
	}
`;

const StyledClose = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 50%;
	cursor: pointer;
	height: 34px;
	position: fixed;
	right: calc(100vw / 24);
	top: 64px;
	width: 34px;
	z-index: 108;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
	transform: rotate(180deg);
	svg {
		width: 34px;
		height: 34px;
	}
	&.sticky {
		top: 34px;
		right: 97px;
	}
	&.active {
		opacity: 1;
		transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
		transform: rotate(360deg);
		visibility: visible;
	}
	//svg {
	//	line {
	//		stroke: var(--middle-color);
	//	}
	//}
`;

const StyledSubpageDescriptionWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.green};
	display: none;
	flex-direction: column;
	height: 100vh;
	justify-content: flex-start;

	overflow-y: scroll;
	::-webkit-scrollbar {
		background: transparent;
		width: 0;
	}
	padding: 212px 80px 48px 0;
	position: fixed;
	right: 0;
	top: 0;
	transform: translateY(-120%);
	transition: transform 0.2s linear;
	width: calc(1 / 3 * 100vw);
	z-index: 106;
	@media (min-width: 1366px) {
		display: flex;
	}

	&.active {
		transform: translateY(0);
		transition: transform 0.2s linear 0.4s;
	}
`;

const StyledContentWrapper = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.darkBlue};
	border-right: 1px solid ${({ theme }) => theme.colors.darkBlue};
	border-top: 1px solid ${({ theme }) => theme.colors.darkBlue};
	display: none;
	height: 100%;
	opacity: 0;
	padding: 0 58px 0 80px;
	transform: translateX(-200px);
	transition: opacity 0.2s linear, transform 0.2s ease-in-out 0.8s;
	z-index: 106;
	@media (min-width: 1366px) {
		display: block;
	}
	@media (max-height: 700px) {
		padding: 0 38px 0 25px;
	}

	&.active {
		opacity: 1;
		transform: translateX(0);
		transition: opacity 0.2s linear 0.8s, transform 0.2s ease-in-out 0.8s;
	}
`;

const CurrentTitleAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  50% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
`;

const StyledSubpageTitleWrapper = styled.div`
	color: ${({ theme }) => theme.colors.darkBlue};
	display: flex;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 1.4375rem;
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	line-height: 2.5rem;
	padding: 40px 0 0 10px;
	position: relative;
	text-transform: uppercase;
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
	width: fit-content;
	@media (min-width: 1440px) {
		font-size: 1.1875rem;
	}
	@media (min-width: 1650px) {
		font-size: 1.4375rem;
	}
	@media (min-height: 801px) {
		padding: 80px 0 0 10px;
	}
	@media (max-height: 800px) and (max-width: 1520px) {
		line-height: 1rem;
		font-size: 0.75rem;
	}
	&.active {
		animation: ${CurrentTitleAnimation} 0.6s linear;
		transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
	}
	&:before {
		background-color: ${({ theme }) => theme.colors.darkBlue};
		bottom: -10px;
		content: '';
		height: 1px;
		left: 0px;
		position: absolute;
		width: calc(100% + 15px);
	}
`;

const StyledPictogramWrapper = styled.div`
	@media (min-height: 900px) {
		margin-top: 100px;
	}
	margin-top: 50px;
	@media (max-height: 700px) {
		margin-top: 30px;
	}
	img {
		width: 120px;
		@media (max-height: 800px) {
			width: 100px;
		}
	}
`;

const StyledCategoryTitle = styled.div`
	color: ${({ theme }) => theme.colors.darkBlue};
	font-size: 3.125rem;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	line-height: 4rem;
	text-transform: uppercase;
	@media (max-height: 800px) and (max-width: 1520px) {
		font-size: 2.125rem;
	}
`;

const StyledDescriptionWrapper = styled.div`
	color: ${({ theme }) => theme.colors.darkBlue};
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 1.25rem;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 2.25rem;
	margin-top: 20px;
	text-decoration: none;
	@media (max-height: 800px) and (max-width: 1520px) {
		line-height: 1.25rem;
		font-size: 0.85rem;
		margin-top: 10px;
	}
	@media (min-height: 900px) {
		margin-top: 40px;
	}

	&.active {
		animation: ${CurrentTitleAnimation} 0.6s ease-in-out;
		transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
	}
`;

class MenuFullscreenDesktopNew extends Component {
	state = {
		activeFirstSubmenuId: -1,
		activeSecondSubmenuId: -1,
		activeThirdSubmenuId: -1,
		activeItemTitle: '',
		activeItemDescription: '',
		addAnimation: false,
		isOpenModal: false,
		linkTo: '',
		isOverColumns: false,
	};

	componentDidMount() {
		const { activeMenuItemId, pageTheme, activeSite } = this.props;
		const defaultTitle = activeSite === 'pl' ? 'wybierz podstronę' : 'choose page';
		this.setState({ activeFirstSubmenuId: activeMenuItemId, activeItemTitle: defaultTitle });
		setInitialColors(pageTheme);
	}

	componentDidUpdate(prevProps, prevState) {
		const { menuFullscreen } = this.props;
		const { activeFirstSubmenu, activeSecondSubmenu, activeThirdSubmenu, isOverColumns } = this.state;

		if (prevProps.menuFullscreen !== menuFullscreen || prevState.isOverColumns !== isOverColumns) {
			if (activeFirstSubmenu !== -1 || activeSecondSubmenu !== -1 || activeThirdSubmenu !== -1) {
				this.setState({
					activeFirstSubmenuId: -1,
					activeSecondSubmenuId: -1,
					activeThirdSubmenuId: -1,
				});
			}
		}
	}

	render() {
		const {
			isMenuFullscreenActive,
			activeMenuItemId,
			activeSite,
			isExpanded,
			dispatch,
			readMore,
			preparedSitemapData,
			parentSlug,
			categoryTitle,
			globals,
			isSticky,
		} = this.props;
		const {
			activeFirstSubmenuId,
			activeSecondSubmenuId,
			activeThirdSubmenuId,
			activeItemTitle,
			activeItemDescription,
			addAnimation,
			isOpenModal,
			linkTo,
			isOverColumns,
		} = this.state;
		const currentCategory = preparedSitemapData.filter((item) => item.originalId === activeMenuItemId);
		const currentCategoryChildren = currentCategory?.[0]?.treeChildren || [];
		const wharfsItem = currentCategoryChildren.filter((item) => item.originalId === '62920680');
		const secondColumn = currentCategoryChildren.filter((item) => item.originalId === activeSecondSubmenuId);
		const secondColumnChildren = secondColumn?.[0]?.treeChildren || [];
		const thirdColumn = secondColumnChildren.filter((item) => item.originalId === activeThirdSubmenuId);
		const thirdColumnChildren = thirdColumn?.[0]?.treeChildren || [];
		const portsPictogram = globals?.nodes?.[0]?.menuPictogramPorts?.url || '';
		const businessPictogram = globals?.nodes?.[0]?.menuPictogramBusiness?.url || '';
		const companyPictogram = globals?.nodes?.[0]?.menuPictogramCompany?.url || '';
		const defaultTitleText = globals?.nodes?.[0]?.defaultTitleText || '';
		const defaultDescriptionText = globals?.nodes?.[0]?.defaultDescriptionText || '';
		const headerText = activeItemTitle || defaultTitleText;
		const modalText = globals?.nodes?.[0]?.modalText || '';
		const btnClose = globals?.nodes?.[0]?.btnClose || '';
		const btnLinkTo = globals?.nodes?.[0]?.btnLinkTo || '';

		const descriptionText = activeItemDescription || defaultDescriptionText;
		const isPortsCategory = parentSlug === 'porty' || parentSlug === 'ports' || parentSlug === 'hafen';
		const isBusinessCategory = parentSlug === 'biznes' || parentSlug === 'business' || parentSlug === 'geschaft';
		const isCompanyCategory = parentSlug === 'spolka' || parentSlug === 'company' || parentSlug === 'unternehmen';
		const categoryPictogram = isPortsCategory
			? portsPictogram
			: isBusinessCategory
			? businessPictogram
			: isCompanyCategory
			? companyPictogram
			: portsPictogram;
		const closeConnectedSubmenus = (stateName) => {
			if (stateName === 'activeFirstSubmenu') {
				this.setState({ activeSecondSubmenu: -1, activeThirdSubmenu: -1 });
			} else if (stateName === 'activeSecondSubmenu') {
				this.setState({ activeThirdSubmenu: -1 });
			}
		};

		const setActiveSubmenu = (originalId, stateName) => {
			if (this.state[stateName] !== originalId) {
				this.setState({ [stateName]: originalId });
			} else {
				// closeConnectedSubmenus(stateName);
				// this.setState({ [stateName]: -1 });
			}
		};

		const setActiveItemTitle = (title) => {
			if (addAnimation === false) {
				this.setState({ addAnimation: true });
			}
			this.setState({ activeItemTitle: title });
		};

		const setActiveItemDescription = (description) => {
			this.setState({ activeItemDescription: description });
		};

		const resetActiveItems = () => {
			this.setState({ activeFirstSubmenuId: -1, activeSecondSubmenu: -1, activeThirdSubmenu: -1, activeItemTitle: '' });
		};

		const togglePopup = () => {
			this.setState({ isOpenModal: !isOpenModal });
		};
		const showModal = (item) => {
			this.setState({ isOpenModal: true });
			this.setState({ linkTo: item.linkToExternalPage });
		};

		const ListItemEl = (item, stateName, className) => {
			let linkOrDivEl = '';

			if (item.linkToExternalPage) {
				if (item.showModal) {
					linkOrDivEl = (
						<div
							onClick={() => showModal(item)}
							onMouseEnter={() => {
								setActiveSubmenu(item.originalId, stateName);
								setActiveItemTitle(item.menuItemTitle);
								setActiveItemDescription(item.menuItemDescription);
							}}
						>
							{item.menuItemTitle}
						</div>
					);
				} else {
					linkOrDivEl = (
						<a
							href={item.linkToExternalPage}
							target="_blank"
							rel="noreferrer"
							onMouseEnter={() => {
								setActiveSubmenu(item.originalId, stateName);
								setActiveItemTitle(item.menuItemTitle);
								setActiveItemDescription(item.menuItemDescription);
							}}
						>
							{item.menuItemTitle}
						</a>
					);
				}
			} else if (item.linkToPage) {
				if (item.isExistingPage) {
					linkOrDivEl = (
						<span className="link-item">
							<Link
								to={`/${getSlug(item.linkToPage, activeSite)}`}
								onClick={() => dispatch(setMenuFullscreen(false))}
								onMouseEnter={() => {
									setActiveSubmenu(item.originalId, stateName);
									setActiveItemTitle(item.menuItemTitle);
									setActiveItemDescription(item.menuItemDescription);
								}}
							>
								{item.menuItemTitle}
							</Link>
						</span>
					);
				} else {
					linkOrDivEl = (
						<span
							className="no-link link-item-no-link"
							onMouseEnter={() => {
								setActiveSubmenu(item.originalId, stateName);
								setActiveItemTitle(item.menuItemTitle);
								setActiveItemDescription(item.menuItemDescription);
							}}
						>
							{' '}
							{item.menuItemTitle}
						</span>
					);
				}
			} else linkOrDivEl = <div className="simple-item">{item.menuItemTitle}</div>;

			return (
				<StyledSingleMenuItem
					className={
						item.originalId === this.state[stateName] && item.treeChildren.length !== 0 ? `${className} visible` : className
					}
				>
					{linkOrDivEl}
					{item.treeChildren.length !== 0 && (
						<div
							style={{ position: 'relative' }}
							onClick={() => {
								setActiveSubmenu(item.originalId, stateName);
							}}
						>
							<StyledHorizontalLine className={item.originalId === this.state[stateName] ? 'visible' : ''} />
						</div>
					)}
				</StyledSingleMenuItem>
			);
		};

		return (
			<>
				<StyledWrapper className={isMenuFullscreenActive ? 'active' : ''}>
					<StyledColumnsMenuWrapper
						className={isMenuFullscreenActive ? 'active' : ''}
						onMouseEnter={() => this.setState({ isOverColumns: true })}
						onMouseLeave={() => this.setState({ isOverColumns: false })}
					>
						<FirstColumn
							onMouseEnter={() => {
								closeConnectedSubmenus('activeSecondSubmenuId');
							}}
						>
							{currentCategoryChildren.map((item) => (
								<div key={item.originalId}>
									{ListItemEl(
										item,
										'activeSecondSubmenuId',
										activeFirstSubmenuId === item.originalId ? 'main-menu active' : 'main-menu',
									)}
								</div>
							))}
						</FirstColumn>
						<SecondColumn
							className={secondColumn.length !== 0 ? 'active' : ''}
							onMouseEnter={() => {
								closeConnectedSubmenus('activeThirdSubmenuId');
							}}
						>
							{secondColumnChildren.map((item) => (
								<div key={item.originalId}>
									{ListItemEl(
										item,
										'activeThirdSubmenuId',
										activeFirstSubmenuId === item.originalId ? 'main-menu active' : 'main-menu',
									)}
								</div>
							))}
						</SecondColumn>
						<ThirdColumn className={thirdColumn.length !== 0 ? 'active' : ''}>
							{thirdColumnChildren.map((item) => (
								<div key={item.originalId}>{ListItemEl(item)}</div>
							))}
						</ThirdColumn>
					</StyledColumnsMenuWrapper>
				</StyledWrapper>
				<StyledSubpageDescriptionWrapper className={isMenuFullscreenActive ? 'active' : ''}>
					<StyledContentWrapper className={isMenuFullscreenActive ? 'active' : ''}>
						{/* <StyledSubpageTitleWrapper className={activeItemTitle ? 'item-title' : 'default'}> */}
						<StyledSubpageTitleWrapper className={addAnimation ? 'active' : ''}>{headerText}</StyledSubpageTitleWrapper>
						{/* <StyledUnderline /> */}
						<StyledPictogramWrapper>
							<img src={categoryPictogram} alt="pictogram" />
						</StyledPictogramWrapper>
						<StyledCategoryTitle>{categoryTitle}</StyledCategoryTitle>
						<StyledDescriptionWrapper className={addAnimation ? 'active' : ''}>{descriptionText}</StyledDescriptionWrapper>
					</StyledContentWrapper>
				</StyledSubpageDescriptionWrapper>

				<StyledClose
					className={
						isMenuFullscreenActive && isSticky
							? 'active sticky'
							: isMenuFullscreenActive && !isSticky
							? 'active'
							: isSticky
							? 'sticky'
							: ''
					}
					onClick={() => {
						resetActiveItems();
						dispatch(setMenuFullscreen(false));
					}}
				>
					<CloseIcon />
				</StyledClose>

				{isOpenModal && (
					<Popup href={linkTo} close={() => togglePopup()} modalText={modalText} btnLinkTo={btnLinkTo} btnClose={btnClose} />
				)}
			</>
		);
	}
}

export default connect((state) => ({
	menuFullscreen: state.menuFullscreen.menuFullscreen,
	pageTheme: state.pageTheme.pageTheme,
}))(MenuFullscreenDesktopNew);
